'use client';

import { useRef, useState } from 'react';

// <FAQ> component is a lsit of <Item> component
// Just import the FAQ & add your FAQ content to the const faqList

const faqList = [
  {
    question: 'What is Yodl?',
    reponse:
      'Yodl is a decentralized payment protocol that enables seamless cross-chain transactions. Send and receive any token across any blockchain network with ease.',
  },
  {
    question: 'How does Yodl work?',
    reponse:
      'Yodl uses advanced cross-chain messaging protocols to facilitate token transfers between different blockchain networks. Simply connect your wallet, select your tokens, and send them to any recipient.',
  },
  {
    question: 'What chains are supported?',
    reponse:
      'We currently support major EVM-compatible chains including Ethereum, Polygon, Arbitrum, and Optimism. More chains are being added regularly.',
  },
  {
    question: 'Are there any fees?',
    reponse:
      'Yodl charges minimal protocol fees while ensuring the best rates for cross-chain transfers. The exact fee depends on the chains and tokens involved in the transaction.',
  },
  {
    question: 'Is Yodl secure?',
    reponse:
      'Security is our top priority. Our smart contracts are thoroughly audited, and we implement industry-best practices to ensure your funds are safe during cross-chain transfers.',
  },
];

const Item = ({ item }: { item: { question: string; reponse: string } }) => {
  const accordion = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <button
        className="relative flex gap-4 items-center w-full py-5 text-base font-semibold text-left border-b md:text-lg border-base-content/10"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        aria-expanded={isOpen}
      >
        <svg
          className={`flex-shrink-0 w-4 h-4 ml-auto fill-[--blue]`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && 'rotate-180'
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && 'rotate-180 hidden'
            }`}
          />
        </svg>
        <span
          className={`flex-1 text-base-content ${isOpen ? 'text-[--foreground]' : 'text-[--foreground]'}`}
        >
          {item?.question}
        </span>
      </button>

      <div
        ref={accordion}
        className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
        style={
          isOpen
            ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="py-5 leading-relaxed">
          <span className="text-[--foreground]">{item?.reponse}</span>
        </div>
      </div>
    </li>
  );
};

const FAQ2 = () => {
  return (
    <section className="max-w-6xl mx-auto" id="faq">
      <div className="py-16 px-8 flex flex-col">
        <div className="text-left lg:mb-4 max-w-xs">
          <h2 className="text-4xl font-semibold text-[--foreground] mb-4">
            Frequently Asked Questions
          </h2>
        </div>
        <ul className="max-w-xl">
          {faqList.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FAQ2;
